<template>
  <div class="contents-section">
    <div class="title-section">
      <h2>DB등록</h2>
      <v-btn
        depressed
        color="success"
        @click="dialog.file.show = true"
      >
        엑셀등록
      </v-btn>
    </div>
    <div class="appendBtnWrap mb-6">
      <div>
        <v-tooltip bottom color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              link
              depressed
              rounded
              min-width="40"
              width="60"
              height="40"
              @click="rowsAppendFunc()"
            >
              <v-icon size="20" left>
                mdi-plus
              </v-icon>
              <p class="btnText">1</p>
            </v-btn>
          </template>
          <span>열 1개 추가</span>
        </v-tooltip>
        <v-tooltip bottom color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              link
              depressed
              rounded
              min-width="40"
              width="70"
              height="40"
              class="ml-2"
              @click="rowsAppendFunc(10)"
            >
              <v-icon size="20" left>
                mdi-plus
              </v-icon>
              <p class="btnText">10</p>
            </v-btn>
          </template>
          <span>열 10개 추가</span>
        </v-tooltip>
      </div>
      <div class="countWrap">
        <p class="mr-4">총 열수 : {{ rows.length }}</p>
      </div>
    </div>
    <div class="registWrap">
      <div
        class="itemRow"
        v-for="(item, index) in rows"
        :key="index"
      >
        <v-text-field
          :persistent-hint="true"
          label="이름"
          class="mr-2 nameTextBox"
          outlined
          dense
          :rules="[nameRule]"
          v-model="item.name"
          color="primary"
          background-color="#FFFFFF"
        ></v-text-field>
        <v-text-field
          :persistent-hint="true"
          label="전화번호"
          class="mr-2 phoneTextBox"
          outlined
          :rules="[phoneRule]"
          dense
          v-model="item.phone"
          color="primary"
          background-color="#FFFFFF"
        ></v-text-field>
        <v-text-field
          :persistent-hint="true"
          label="기타정보"
          :rules="[memoRule]"
          class="mr-2"
          outlined
          dense
          v-model="item.memo"
          color="primary"
          background-color="#FFFFFF"
        ></v-text-field>
        <div class="mb-6 mr-2">
          <v-menu offset-y :close-on-content-click="true">
            <template #activator="{ on, attrs }">
              <v-btn dense depressed height="40" outlined
                v-bind="attrs" v-on="on"
                @click="getSourceList"
              >
                {{ item.source == null?'유입업체선택':`유입업체: ${item.source}` }}
              </v-btn>
            </template>
            <div class="text-input">
              <v-text-field
                :autofocus="true"
                solo
                v-model="item.sourceDup"
                dense
                flat
                hide-details
                color="black"
                :placeholder="sourceList.length > 0?'직접입력 혹은 목록에서 선택':'직접입력'"
                outlined
              ></v-text-field>
            </div>
            <v-list v-if="sourceList.length > 0
              && (item.sourceDup == null || item.sourceDup.trim().length == 0)"
              class="source-list"
            >
              <v-list-item
                v-for="(list, i) in sourceList"
                :key="i"
                dense
                nav
                link
                @click="sourceInsertRow(index, list.name)"
              >
                <v-list-item-title>{{ list.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
            <v-list v-else-if="item.sourceDup != null && item.sourceDup.length > 0">
              <v-list-item dense
                nav
                link
                @click="sourceInsertRow(index, item.sourceDup)">
                <v-list-item-title>{{ item.sourceDup }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="mb-6 mr-2">
          <v-btn
            depressed
            outlined
            height="40"
            @click="dialogIndex = index;
              dialog.datePicker.show = true;
              dialog.datePicker.date = item.date"
          >
            유입일: {{ item.date }}
          </v-btn>
        </div>
        <div class="mb-6">
          <v-tooltip bottom color="black" v-if="rows.length !== 1">
            <template #activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                link
                depressed
                rounded
                min-width="40"
                width="40"
                height="40"
                @click="rows.splice(index, 1); dialogIndex = 0"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
            <span>열 삭제</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="btnWrap mt-4 mb-15">
      <v-btn
        depressed
        color="primary"
        @click="registContact"
      >
        {{ rows.length }}건 등록하기
      </v-btn>
    </div>
    <v-dialog
      v-model="dialog.file.show"
      @click:outside="fileInit()"
      @keydown="$event.key === 'Escape' ? fileInit() : ''"
      scrollable
      max-width="500"
    >
      <v-card>
        <v-card-title
          class="d-flex align-center justify-space-between"
        >
          <span class="text-h5">엑셀등록</span>
          <div class="textBox">
            <v-text-field
              class="mb-2"
              label="유입업체명"
              outlined
              hide-details
              v-model="sourceName"
              dense
            ></v-text-field>
          </div>
        </v-card-title>
        <v-card-text>
          <input type="file" class="fileInput" ref="fileInput"
            @change="fileZoneFileChange"
          />
          <div class="fileZone"
            v-if="dialog.file.data === null"
            :class="isDragged ? 'dragged' : ''"
            @dragenter="fileZoneDragenter"
            @dragover="fileZoneDragover"
            @dragleave="fileZoneDragleave"
            @drop="fileZoneDrop"
            @click="fileZoneClick"
          >
            <v-icon large class="mb-2">mdi-cloud-upload-outline</v-icon>
            <p class="mb-2">엑셀파일을 여기에 끌어오거나 클릭하여 파일을 선택하세요.</p>
          </div>
          <div class="fileZoneDup" v-else>
            <v-chip
              class="fileChip"
              close
              label
              color="success"
              @click:close="dialog.file.data = null"
            >
              <span>
                {{ dialog.file.data.name }}({{ fileUtils.byteTrans(dialog.file.data.size) }})
              </span>
            </v-chip>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-6">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            :disabled="dialog.file.data === null
              || sourceName == null
              || sourceName.trim().length <= 0"
            @click="uploadFileFunc"
          >
            업로드
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog.result.show"
      persistent
      scrollable
      max-width="500"
    >
      <v-card>
        <v-card-title
          class="d-flex align-center pt-6"
        >
          <span class="text-h5">직원등록결과</span>
          <v-chip small class="ml-2" color="success">성공: {{ dialog.result.successCnt }}건</v-chip>
          <v-chip small class="ml-1" color="error">실패: {{ dialog.result.failCnt }}건</v-chip>
        </v-card-title>
        <v-card-text>
          <v-simple-table ref="resultTable">
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    이름(아이디)
                  </th>
                  <th class="text-left">
                    비밀번호
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in dialog.result.data"
                  :key="item.id"
                >
                  <td>{{ item.name }}({{ item.id }})</td>
                  <td>{{ item.newPassword }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-6">
          <v-spacer></v-spacer>
          <v-btn
            depressed
            @click="resultTableCopy"
          >
            <v-icon small class="mr-1">mdi-content-copy</v-icon>
            결과복사하기
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="dialog.result.show = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      @click:outside="dialog.datePicker.show = false;"
      v-model="dialog.datePicker.show"
      width="290px"
    >
      <v-date-picker
        class="datePickerContent"
        v-model="dialog.datePicker.date"
        @input="rows[dialogIndex].date = $event"
        no-title
      >
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import time from '@/util/time';
// import * as XLSX from 'xlsx';
import axios from 'axios';
import path from '@/util/path';
import fileUtils from '@/util/file';

export default {
  components: { },
  data: () => ({
    fileUtils,
    sourceName: null,
    sourceList: [
    ],
    dialogIndex: 0,
    isDragged: false,
    dialog: {
      result: {
        show: false,
        successCnt: 0,
        failCnt: 0,
        data: [],
      },
      file: {
        show: false,
        data: null,
      },
      datePicker: {
        show: false,
        date: null,
      },
    },
    defaultItem: {
      name: null,
      phone: null,
      memo: null,
      date: time.moment(new Date()).format('YYYY-MM-DD'),
      source: null,
      sourceDup: null,
    },
    rows: [
      {
        name: null,
        phone: null,
        date: time.moment(new Date()).format('YYYY-MM-DD'),
        memo: null,
        source: null,
        sourceDup: null,
      },
    ],
    nameRule: (value) => {
      const reg = /^[a-zA-Z가-힣ㄱ-ㅎ]{1,15}$/g;
      return reg.test(value) || '1~15자 한글, 영문';
    },
    phoneRule: (value) => {
      const reg = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
      return reg.test(value) || '휴대전화번호 형식에 맞춰주세요.';
    },
    memoRule: (value) => (value !== null && value.length < 200) || '기타정보는 최대 200자 입니다.',
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      companyInfo: 'auth/companyInfo',
      router: 'router/path',
    }),
  },
  methods: {
    sourceInsertRow(index, value) {
      this.rows[index].source = value;
      this.rows[index].sourceDup = null;
    },
    ...mapActions({
      alert: 'dialog/alert',
    }),
    ...mapMutations({
      progress: 'dialog/progress',
    }),
    rowsDeleteFunc(seq) {
      const newRows = this.rows.filter((item) => item.seq !== seq);
      this.rows = newRows;
    },
    rowsAppendFunc(num = 1) {
      for (let i = 0; i < num; i += 1) {
        if (this.rows.length < 30) {
          this.rows.push({ ...this.defaultItem });
        } else {
          this.alert(['error', '수기 등록은 한번에 최대 30명까지 등록 가능합니다.']);
        }
      }
    },
    // TODO: DB 다중등록 (no 엑셀)
    registContact() {
      this.progress('true');
      const reqObj = { items: [] };
      let checkVal = true;
      for (let i = 0; i < this.rows.length; i += 1) {
        if (
          this.nameRule(this.rows[i].name) !== true
          || this.phoneRule(this.rows[i].phone) !== true
          || this.memoRule(this.rows[i].memo) !== true
          || this.rows[i].source == null
          || this.rows[i].source.trim().length <= 0
        ) {
          checkVal = false;
        } else {
          const rowObj = {
            name: this.rows[i].name,
            phone: this.rows[i].phone,
            memo: this.rows[i].memo,
            date: this.rows[i].date,
            // source: this.rows[i].source,
          };
          reqObj.items.push(rowObj);
        }
      }
      if (checkVal === false) {
        this.alert(['error', '입력정보를 확인해주세요.']);
        this.progress('false');
      } else {
        this.$socket.emit('contact.add', reqObj, (resp) => {
          if (resp.success > 0) {
            this.alert(['success', '등록이 완료되었습니다.']);
            if (resp.fails > 0) {
              reqObj.items.forEach((row, index) => {
                const fails = resp.failsInfo.filter((r) => row.id === r.id);
                if (fails.length > 0) {
                  reqObj.items.splice(index, 1);
                }
              });
            }
            this.dialog.result.data = reqObj.items;
            this.dialog.result.failCnt = resp.fails;
            this.dialog.result.successCnt = resp.success;
            this.dialog.result.show = true;
          } else if (resp.success === 0 && resp.fails > 0) {
            this.alert(['error', '입력정보를 확인해주세요. 중복아이디는 허용되지 않습니다.']);
            console.error(resp);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
          this.progress('false');
        });
      }
    },
    resultTableCopy() {
      const table = this.$refs.resultTable.$el.innerText;
      navigator.clipboard.writeText(table);
      this.alert(['success', '클립보드에 복사되었습니다.']);
    },
    fileInit() {
      this.dialog.file.show = false;
      this.dialog.file.data = null;
      this.$refs.fileInput.value = null;
    },
    fileZoneClick() {
      this.$refs.fileInput.click();
    },
    fileZoneDragenter() {
      this.isDragged = true;
    },
    fileZoneDragleave() {
      this.isDragged = false;
    },
    fileZoneDragover(event) {
      event.preventDefault();
    },
    fileZoneDrop(event) {
      event.preventDefault();
      this.isDragged = false;
      if (event.dataTransfer.files.length !== 1) {
        this.alert(['error', '파일은 1개만 등록가능합니다.', 4000]);
      } else {
        this.uploadeFileCheck(event.dataTransfer.files[0]);
      }
    },
    fileZoneFileChange(event) {
      this.uploadeFileCheck(event.target.files[0]);
    },
    uploadeFileCheck(file) {
      if (file != null) {
        const typeArr = [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel',
        ];
        const typeCheck = typeArr.includes(file.type);
        const sizeCheck = file.size <= 10485760;
        if (typeCheck === false) {
          this.alert(['error', '엑셀파일만 업로드 가능합니다.']);
          this.dialog.file.data = null;
        } else if (sizeCheck === false) {
          this.alert(['error', '최대 10MB 까지 업로드 가능합니다.']);
          this.dialog.file.data = null;
        } else {
          this.dialog.file.data = file;
        }
      }
      this.$refs.fileInput.value = null;
    },
    uploadFileFunc() {
      this.progress(true);
      const data = new FormData();
      data.append('source', this.sourceName.trim());
      data.append('file', this.dialog.file.data);
      const token = localStorage.getItem(`MANOadmin.${this.companyInfo.code}.authToken`);
      const headers = {
        headers: {
          token,
        },
      };
      axios.post(`${path.apiHost}${path.apiHostBase}/contact`, data, headers)
        .then(() => {
          this.dialog.file.show = false;
          this.dialog.file.data = null;
          this.alert(['success', '업로드가 진행중입니다. 진행도는 우측상단에서 확인해볼 수 있습니다.']);
          this.progress(false);
        })
        .catch((err) => {
          console.error(err);
          this.alert(['error', '오류가 발생했습니다. 다시 시도해주세요.']);
          this.progress(false);
        });
    },
    getSourceList() {
      this.$socket.emit('contact.source.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.sourceList = resp.items;
        } else {
          this.sourceList = [];
        }
      });
    },
    checkFuncPermission(funcName) {
      try {
        const userPermission = { ...this.userInfo.permission.cs.menu.db.func };
        let result = false;
        if (
          userPermission[funcName] !== undefined
          && userPermission[funcName].bool === true
        ) result = true;
        return result;
      } catch (error) {
        console.error('checkFuncPermission error');
        return false;
      }
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          if (!this.checkFuncPermission('regist')) {
            this.alert(['error', '잘못된 접근입니다.']);
            this.$router.push('/');
          }
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.registWrap {
  .itemRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom:-5px;
    .nameTextBox {
      max-width: 150px;
    }
    .phoneTextBox {
      max-width: 200px;
    }
    .radioWrap {
      flex-shrink: 0;
    }
  }
}
.appendBtnWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btnText {
    font-size: 1.1rem;
    margin-bottom:2px;
  }
  .countWrap {
    display: flex;
    p{
      margin:0;
      font-size: .9rem;
    }
  }
}
.btnWrap {
  display: flex;
  justify-content: center;
}
.fileInput {
  display: none;
}
.fileZone {
  width: 100%;
  margin-top:20px;
  height: 150px;
  border:3px dashed #ccc;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  opacity: .7;
  cursor: pointer;
  transition: .3s ease-in-out;
  color: #000;
  font-size: .9rem;
  flex-wrap: wrap;
  align-content: center;
  &:hover {
    opacity: 1;
    border-color: skyblue;
  }
  p {
    margin:0;
    width: 100%;
    text-align: center;
  }
  &.dragged {
    opacity: 1;
    border-color: skyblue;
  }
}
.fileZoneDup {
  width: 100%;
  margin-top:20px;
  margin-bottom:-10px;
  display: flex;
  justify-content: center;
  align-items: center;
  .fileChip {
    width: 100%;
    justify-content: center;
    span {
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.source-list {
  max-height: 300px;
  overflow: auto;
}
</style>
